.btn {
	padding: 1rem 2rem;
	font-size: 1.3rem;
	text-transform: uppercase;
	font-family: $font-family-base;

	&-light {
		background-color: transparent;
		border-color: #fff;
		color: #FFF;

		@include hover-focus {
			border-color: transparent;
			background-color: #fff;
			color: #000;
		}
	}

	&-grey {
		border-color: $color-light;
		color: #FFF;

		@include hover-focus {
			border-color: transparent;
			background-color: #fff;
			color: #000;
		}
	}

	&-green-light {
		background-color: transparent;
		border-color: $color-green;
		color: $color-green;

		@include hover-focus {
			background-color: $color-green;
			color: #FFF;
		}
	}
	&-green {
		border-color: $color-green;
		background-color: $color-green;
		color: #FFF;
		@include hover-focus {
			background-color: transparent;
			color: $color-green;
		}
	}


	&-small {
		padding: 0.35rem 1rem;
		font-size: 1rem;
	}
}