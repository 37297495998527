
html {

	@media (max-width: map-get($grid-breakpoints, lg)) {
		font-size: 14px;
	}

}

h1, .h1 {
	text-transform: uppercase;
	margin-bottom: 1rem;
}

h2, .h2 {
	text-transform: uppercase;
	margin-bottom: 2.5rem;
}

h3, .h3 {
	text-transform: uppercase;
	margin-bottom: 2.5rem;
}

p {
	font-family: $font-family-text;
	font-weight: 300;
	margin-bottom: 2rem;

	+ .btn {
		margin-top: 2rem;
	}
}

.lead {
	font-family: $font-family-base;
	margin-bottom: 3rem;
}

.f {
	&-light {
		font-weight: 300;
	}

	&-regular {
		font-weight: 400;
	}

	&-bold {
		font-weight: 700;
	}
}