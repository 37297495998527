//font-family: 'Open Sans', sans-serif;
//font-family: 'Oswald', sans-serif;


// bootstrap 4
$enable-rounded: false;

$btn-font-weight: 300;

// fontawesome
$fa-font-path: '../../bower_components/font-awesome/fonts';



$img: '../images';



$font-family-base: 'Oswald', sans-serif;
$font-family-text: 'Open Sans', sans-serif;

$color-green-low: #7fcea3;
$color-green: #009d47;
$color-green-h: #008131;
$color-green-nav: #006125;

$color-light: #4d4d4d;

$color-orange: #ff3600;

$font-weight-base: 300;
$headings-font-family: $font-family-base;
$headings-font-weight: 300;
$font-size-h1: 3.75rem;
$font-size-h2: 2.5rem;
$font-size-h3: 2rem;
$font-size-h4: 1.25rem;
//$font-size-h4: 1.5rem !default;
//$font-size-h5: 1.25rem !default;
//$font-size-h6: 1rem !default;


$lead-font-size: 1.15rem;
$lead-font-weight: 300;

$link-color: $color-green;
$link-hover-decoration: none;

$navbar-toggler-padding-y: 0.75rem;
$navbar-inverse-color: #FFF;




$table-cell-padding: 0.5rem;
$table-head-color: #FFF;
$table-head-bg: $color-green;
$table-border-color: $color-light;





//$input-color
//$input-line-height
$input-group-addon-bg: transparent;
$input-group-addon-border-color: transparent;

