.footer {
	.contact {
		background-color: #FFFFFF;
		color: #000000;
	}
	.copyright {
		background-color: #000000;
		color: #FFFFFF;

		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}