.table {
	th, td {
		text-align: center;
		border-top: 0;
		border-bottom: $table-border-width solid $table-border-color;
	}
	thead th {
		vertical-align: middle;
		border-top: 0;
		border-bottom: 0;
	}
}

.flexslider {
	margin: 0;
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	margin-bottom: 2rem;
	//overflow: hidden;
}

.flex-viewport {
	border: 1px solid $color-light;
	margin-bottom: 1rem;
}

.flex-control-thumbs {
	li {
		border: 1px solid $color-light;
		min-height: 4.5rem;
		line-height: 4.5rem;
		position: relative;
		overflow: hidden;
	}

	img {
		display: inline-block;
		vertical-align: middle;
	}
}

.flex-direction-nav a:before {
	color: #fff;
	font-size: 2rem;
}

.flex-control-paging li a {

	border-radius: 0;
	background-color: transparent;
	border: 1px solid #fff;
	box-shadow: none;

	&.flex-active {
		background-color: #fff;
	}
}

.slider-page {
	img {
		width: auto !important;
		max-height: 600px;
	}
}

.button-login {
	background-color: $color-green-h;
	cursor: pointer;
	margin-bottom: 2.5rem;

	.label {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		text-transform: uppercase;
		font-weight: 700;
	}

	.square {
		display: block;
		width: 3rem;
		height: 3rem;
		line-height: 3rem;
		background-color: $color-orange;
		text-align: center;
		margin-right: -1px;
	}
}

.pagination {
	span, a {
		display: block;
		width: 1.5rem;
		line-height: 1.5rem;
		//background-color: #00b3ee;
		text-align: center;
		color: #fff;
		border: 1px solid transparent;

		@include hover {
			color: $color-green;
		}
	}
	span {
		cursor: default;
		font-weight: 800;
		border-color: $color-green;

		&, &:hover {
			color: #FFFFFF;
		}
	}

}

.sidebar {
	position: relative;
	.box-login {
		position: absolute;
		top: -5rem;
		width: 100%;

		.login & {
			display: none;
		}
	}
}


.logos-sidebar {
	display: block;
	margin: 2rem auto;
	max-width: 100%;
	filter: grayscale(100%);
	transition: all 200ms ease-in-out;
	@include hover {
		filter: grayscale(10%);
	}
}







