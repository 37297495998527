

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
}

.sidebar {


	.box-sidebar {
		margin-bottom: 2.5rem;
	}

	h3 {
		border-top: 2px solid #FFF;
		margin-bottom: 1rem;
		padding: 1rem 0;
	}


	ul {
		list-style: none;
		padding-left: 0;

		border-top: 1px solid $color-light;

		> li {
			border-bottom: 1px solid $color-light;

			> a {
				display: block;
				padding: 0.3rem 0;
			}
		}
	}
}