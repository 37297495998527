.main {
	.section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.section-content {
		padding-top: 8rem;
		padding-bottom: 3rem;

		.login & {
			padding-top: 8rem - ( 3 + 2 ); // zalogowani
		}
	}
}

.footer {
	.section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}


.page-start {
	color: #FFF;
	background-color: #CCCCCC;
	background-image: url("#{$img}/bg.svg"), url("#{$img}/start.jpg");
	background-position: center center;
	background-size: auto, cover;
	&:before {
		content: '';
		background-color: rgba(#000, 0.5);
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100vh;
	}

	.row-main {
		min-height: 100vh;
	}

	.logo {
		margin-bottom: 2rem;
	}
}



.box-news {
	padding-bottom: 3rem;
	.lead {
		margin-bottom: 0;
	}
}



.section-about {

}

.section-products {
	background-color: #000000;
	color: #FFF;

	.view-more {
		margin-top: 2rem;
	}
}

.box-product {
	border: 1px solid #4d4d4d;
	padding: 1rem 1rem;
	margin-bottom: 2rem;
	text-align: center;

	.image {
		margin-bottom: 1.25rem;
	}
	.title {
		font-weight: 400;
		margin-bottom: 0;
		text-transform: uppercase;
	}

	.btn {
		margin-top: 2rem;
	}
}





.section-contact {
	.info {
		text-align: center;
		font-family: $font-family-text;
		margin-bottom: 3rem;

		.p1 {
			font-size: 2rem;
			font-weight: 300;
			span {
				color: $color-green;
			}
		}

		.p2 {
			font-size: 1.25rem;
			font-weight: 700;
		}
	}

	.icons {
		margin-bottom: 3rem;

		.icon {
			font-size: 3rem;
		}
		.flag {
			width: 1.5rem;
		}
		a {
			&[href^='tel'] {
				font-size: 1.75rem;
			}
			&[href^='mailto'] {
				font-size: 1.25rem;
				text-decoration: underline;
			}

			color: $color-green;
		}
	}
}




.section-content {
	background-color: #000000;
	color: #FFF;

	.main-title {
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		&:before {
			content: '';
			height: 2px;
			width: 170px;
			background-color: #FFF;
			display: block;
			margin-bottom: 1rem;
		}
	}


	aside {
		font-size: 1.25rem;
		
		a {
			color: #FFF;
		}
	}
}

