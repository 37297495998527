.header {
	position: relative;
	overflow: hidden;
	background-image: url("#{$img}/bg.svg");
	background-position: center top;
	color: #FFF;

	// tylko jak jest baner
	.home & {
		padding-bottom: 3rem;
		margin-bottom: 3rem;
	}

	&:before {
		content: '';
		background-color: rgba(#000, 0.5);
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
	}

	.top {
		padding-top: 3.5rem;
		padding-bottom: 1.3rem;
	}

	.navigation {
		background-color: rgba($color-green-nav, 0.5);
		line-height: 1;
	}

	.head {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

//.navbar {
//	padding-top: 0;
//	padding-bottom: 0;
//}

.navbar-nav {
	font-weight: 300;
	font-size: 1.25rem;
	text-transform: uppercase;

	.nav-link {
		color: #fff;

		@include hover-focus {
			color: #fff;
		}
	}
}

//
//.zxccc {
//	> li { //.nav-item
//		> a {
//			@include nav-link;
//		}
//	}
//}

.list-social {
	font-size: 1.25rem;
	padding: $nav-link-padding;
	padding-right: 0;
	margin-bottom: 0;
	list-style: none;
	//width: 150px;

	@include media-breakpoint-down(md) {
		padding-left: 0;
	}


	> li {
		display: inline-block;
		padding-left: 0.5rem;
		@include media-breakpoint-down(md) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		> a {
			color: #FFF;
		}
	}
}

.list-lang {
	a {
		color: #FFFFFF;
	}
}

.header-background {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;

	.bg-slide {
		position: absolute;
		min-height: 700px;
		width: 100%;
		top: 0;
		bottom: 0;
		background-size: cover;
		background-position: top center;
	}
}

.banner-link {
	color: #FFFFFF;
	left: 0;
	right: 0;
	margin-top: -7rem;

	@include hover-focus {
		color: #FFFFFF;
	}
}

.banner {
	font-size: 3.75rem;
	line-height: 1.2;

	.text {
		min-height: 8rem;
		background-color: $color-green;
		background-image: url("#{$img}/banner-bg.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		text-align: center;
		font-weight: 800;
		text-transform: uppercase;
		width: 100%;

		padding: 1rem;

		@include media-breakpoint-down(md) {
			font-size: 3rem;
		}
		@include media-breakpoint-down(sm) {
			font-size: 2.5rem;
		}

	}
	.square {
		background-color: $color-orange;
		min-width: 8rem;
		min-height: 8rem;
		line-height: 8rem;
		text-align: center;
	}
}

.navbar-toggler {
	padding: 0.3rem;
	border: 0;
}



.search-form {

	.form-control {
		font-family: $font-family-base;
		border-width: 0;
		background-color: rgba($color-green-low, 0.5);
		border-radius: 0.25rem;

		transition: all 300ms ease-in-out;

		width: 6rem;

		.login & {
			width: 5.5rem;
		}

		@include media-breakpoint-down(md) {
			width: 10rem;
		}

		&::placeholder {
			color: rgba(#fff, 0.5);
		}


		&:focus {
			background-color: rgba(#fff, 0.8);
			&::placeholder {
				color: rgba(#000, 0.5);
			}
		}
	}

	.btn {
		color: #FFF;
		font-size: 1.25rem;
		padding-left: 1rem;
		padding-right: 0.5rem;
		&, &:focus {
			outline: none;
			box-shadow: none;
		}
	}
}